import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import BlogItems from '../components/items-blog'
import SectionTitle from '../components/sectiontitle'
import Pagination from '../components/pagination'
import SEO from '../components/seo'

class BlogList extends React.Component {
  render() {
    const query = this.props.datas
    if (query.allMarkdownRemark.edges.length > 0) {
      return (
        <section id="blog" className="container">
          <div className="section-title">
            <SectionTitle title="BLOG" />
          </div>
          <BlogItems data={query} />
          <Pagination pageContext={this.props.pageContext} type="blog" />
        </section>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}

export default function({data, pageContext}) {
  return (
    <Layout>
      <SEO lang="en" title="Blog" />
      <BlogList datas={data} pageContext={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query blogListPage($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            date
            published
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  srcSet
                  ...GatsbyImageSharpFluid
                }
                id
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
